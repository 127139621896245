import React from "react"
import { Link } from "gatsby"
import Img from "gatsby-image"
import Helmet from "react-helmet"

import Layout from "./layout"
import SEO from "./seo"

const Product = ({
  title,
  description,
  shortDescription,
  price,
  images,
  gumroadlink,
  preorder,
  limited,
  social,
  slug,
}) => {
  const meta = `Buy a ${limited &&
    `numbered limited edition `}giclée art print of the illustration ‘${title}’ by Eleni Debo`
  const socialImgURL = `https://shop.elenidebo.com${social.childImageSharp.fixed.src}`
  const schemaImgURL = `https://shop.elenidebo.com${images[0].childImageSharp.fluid.src}`
  const mailLink = `mailto:shop@elenidebo.com?subject=${
    preorder ? `Pre-ordering` : `Ordering`
  } “${title}”&body=Hi Eleni! I would like to ${
    preorder ? `pre-order` : `order`
  } “${title}”%0D%0A%0D%0AMy name: %0D%0AShip it to: `
  const schema = {
    "@context": "http://schema.org",
    "@type": "Product",
    name: title,
    image: {
      "@type": "ImageObject",
      url: schemaImgURL,
    },
    mainEntityOfPage: `https://shop.elenidebo.com${slug}`,
    description: limited
      ? `A limited edition giclée art print of ${title} by Eleni Debo. ${shortDescription}`
      : `An open edition giclée art print of ${title} by Eleni Debo. ${shortDescription}`,
    offers: {
      "@type": "Offer",
      url: `https://shop.elenidebo.com${slug}`,
      priceCurrency: "EUR",
      price: price.toFixed(2),
      availability: preorder
        ? "http://schema.org/PreOrder"
        : "http://schema.org/InStock",
    },
  }
  return (
    <Layout id="product">
      <SEO
        title={`${title} | fine art print by Eleni Debo`}
        description={meta}
      />
      <Helmet>
        <meta property="og:image" content={socialImgURL} />
        <meta name="twitter:image" content={socialImgURL} />
        <script type="application/ld+json">{JSON.stringify(schema)}</script>
      </Helmet>
      <div className="product">
        <div className="images">
          {images.map((image, i) => (
            <div key={i} className="image">
              <Img
                placeholderClassName="placeholder"
                fluid={image.childImageSharp.fluid}
              />
            </div>
          ))}
        </div>
        <div>
          <h1>{title}</h1>
          <h2>
            {limited
              ? `Limited edition giclée art print`
              : `Open edition giclée art print`}
          </h2>
          {description}
          <div className="buy">
            <span className="price">
              €{price.toFixed(2).replace(/\./g, ",")}
            </span>
            <p>
              Price is exclusive shipping. I’ll send you payment instructions
              and let you know the shipping cost based on the address you
              provide.
            </p>
            <div>
              <a href={mailLink} className="button" type="button">
                {preorder ? `Pre-order now` : `Buy now`}
              </a>
              <Link to="/" style={{ fontSize: `0.875em` }}>
                or browse all prints
              </Link>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default Product
