import React from "react"
import { Link } from "gatsby"

const Layout = ({ children, id }) => {
  return (
    <div id={id}>
      <Header />
      <div className="container">
        {/* <Message>
          I’m currently updating my shop but will accept new orders soon
        </Message> */}
        <section className="content">{children}</section>
      </div>
    </div>
  )
}

const Header = () => (
  <header>
    <h1>Eleni Debo</h1>
    <nav>
      <a className="portfolio" href="http://elenidebo.com/">
        Portfolio
      </a>
      <Link className="shop active" to="/">
        Shop
      </Link>
      <a className="about" href="http://elenidebo.com/about">
        About
      </a>
    </nav>
  </header>
)

export default Layout

const Message = ({ children }) => (
  <div className="message">
    <p>{children}</p>
  </div>
)
